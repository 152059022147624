import { useRef, useState, useEffect } from  'react';
import { Link, useLocation } from 'react-router-dom';

import { Header } from './Header';
import { Card, CardBody, CardHeader, CardFooter, SimpleGrid, Button, Box, Input, useToast } from '@chakra-ui/react';

import axios from 'axios';

export const Date = () => {
    const location = useLocation();
    const toast = useToast();

    const searchRef = useRef();

    const nullKeys = {"keys":[],"list_complete":true,"cacheStatus":null}
    const [keys, setKeys] = useState(nullKeys);
    const [values, setValues] = useState([]);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const key = urlParams.get('key');
        if (key) {
            searchRef.current.value = key
        }
        getKeys();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { getValues(); }, [keys]);

    const getKeys = async() => {
        try{
            const rsp = await axios({
                method: 'get',
                url: '/api/date',
                params: { search: searchRef.current.value } 
            });
            if(rsp.status === 200) {
                setKeys(rsp.data);
            } else {
                setKeys(nullKeys);
                toast({
                    title: `No data getKeys`,
                    status: 'info',
                    isClosable: true,
                });
            }
        } catch(error){
            setKeys(nullKeys);
            toast({
                title: `Error getKeys`,
                description: error.message,
                status: 'error',
                isClosable: true,
            });
        }
    }

    const getValues = async() => {
        const valueList = [];
        for(let key of keys.keys) {
            const value = await getValue(key);
            if (value) {
                valueList.push(value);
            }
        }
        setValues(valueList);
    }

    const getValue = async(key) => {
        if (!key || !key.name) {
            toast({
                title: `Error parameter missing`,
                status: 'error',
                isClosable: true,
            });
            return;
        }

        key = key.name.split(':')[1];
        try{
            const rsp = await axios({
                method: 'get',
                url: `/api/date/${key}` 
            });
            if(rsp.status === 200) {
                return rsp.data;
            } else {
                toast({
                    title: `Code: ${rsp.status}`,
                    status: 'waring',
                    isClosable: true,
                });
                return;
            }
        } catch(error){
            toast({
                title: `Error getValue`,
                description: error.message,
                status: 'error',
                isClosable: true,
            });
            return;
        }
    }

    return (
        <>
            <Header position={'date'} />
            <Box margin='5px' display='flex' alignItems='center' height='50px'>
                <Button margin='5px'>RECENT DATE</Button>
                <Button onClick={getKeys} margin='5px'>SEARCH</Button>
                <Input ref={searchRef} onKeyUp={(e) => { if (e.key === 'Enter') getKeys(e); }} margin='5px' type='text' placeholder='SEARCH...'/>
            </Box>
            <SimpleGrid flexWrap="wrap" width='100%' padding='5px' margin='5px' minChildWidth='sm' spacing='10px'>
                {values.map((value, idx) => (
                    <Box key={idx} maxW='lg' mr={['0', '5px']} flexGrow={[1, 0]}>
                        <Card>
                            <CardHeader fontWeight={700} display="flex" justifyContent="space-between" alignItems="center">
                                <a href={value.url} target='_blank' rel='noreferrer'>{value.key}</a>
                            </CardHeader>
                            <CardBody>
                                <div dangerouslySetInnerHTML={{__html: value.summary}} />
                                <br/>
                                <div>candy: <Link to={`/candy?key=${value.candy}`}>{value.candy}</Link></div>
                                <div>info: <Link to={`/info?key=${value.info}`}>{value.info}</Link></div>
                            </CardBody>
                            <CardFooter justifyContent='flex-end'>
                                {value.index.map((idx) => {
                                    if (typeof idx === 'object') {
                                        const key = Object.keys(idx)[0];
                                        return <div>{`${key}: ${idx[key]}`}</div>;
                                    } else {
                                        return <div>{`${idx}`}</div>
                                    }
                                })}
                            </CardFooter>
                        </Card>
                    </Box>
                ))}
            </SimpleGrid>
        </>
    )
}
