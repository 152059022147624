import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Checkbox, Container, FormControl, FormLabel, Heading, HStack, Input, Link, Stack, Text } from '@chakra-ui/react'

import { Logo } from '../components/Logo'
import { PasswordField } from '../components/PasswordField'

import axios from 'axios';

export const Signin = () => {
  const navigate = useNavigate();

  const [userid, setUserId] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    // event.preventDefault();

    try{
      const rsp = await axios({
        method: 'post',
        url: '/sign/in',
        data: { userid, password } 
      });
      if(rsp.status === 200) {
        console.log('Signin successful');
        navigate('/')
      } else {
         console.log('Signin failed');
         alert(rsp.data.message);
      }
    } catch(error){
      console.log('There was an error trying to sigin: ', error);
      alert(error.message);
    }
  };
  
  return (
    <Container maxW='lg' py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
      <Stack spacing='8'>
        <Stack spacing='6'>
          <Logo />
          <Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
            <Heading size={{ base: 'xs', md: 'sm' }}>Log in to your account</Heading>
            <Text color='fg.muted'>
              Don't have an account? <Link href='/signup' color={'tomato'}>Sign up</Link>
            </Text>
          </Stack>
        </Stack>
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={{ base: 'transparent', sm: 'bg.surface' }}
          boxShadow={{ base: 'none', sm: 'md' }}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Stack spacing='6'>
            <Stack spacing='5'>
              <FormControl>
                <FormLabel htmlFor='email'>Email</FormLabel>
                <Input id='email' type='email' onChange={(e) => setUserId(e.target.value)} />
              </FormControl>
              <PasswordField onChange={(e) => setPassword(e.target.value)} onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit(e); }}/>
            </Stack>
            <HStack justify='space-between'>
              <Checkbox defaultChecked>Remember me</Checkbox>
              <Button variant='text' size='sm'>
                Forgot password?
              </Button>
            </HStack>
              <Button onClick={handleSubmit}>Sign in</Button>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}