import React, {useState, useCallback} from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';

import { PageRouter } from './pages/PageRouter'
import { AxiosConfig } from './axiosConfig';
import AppContext, { getDefaultState } from './appContext';

function App() {
  AxiosConfig();

  const [state, setState] = useState(getDefaultState())
  const setContext = useCallback(
    updates => {
      setState({ ...state, ...updates })
    },
    [state, setState],
  )
  const getContextValue = useCallback(
    () => ({
      ...state,
      setContext,
    }),
    [state, setContext],
  )
  
  return (
    <ChakraProvider theme={theme}>
      <AppContext.Provider value={getContextValue()}>
        <PageRouter />
      </AppContext.Provider>
    </ChakraProvider>
  );
}

export default App;
