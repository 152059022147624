import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Flex, Text, Button, Link } from '@chakra-ui/react';

import { ColorModeSwitcher } from '../components/ColorModeSwitcher';

export const Header = ({ position }) => {
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/signin');            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box as='header' px={4}>
            <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                <Flex alignItems={'center'} gap={4}>
                    <Link onClick={() => { navigate('/'); }}><Text fontSize={'2xl'} fontWeight={7000}>https://</Text></Link>
                    <Link onClick={() => { navigate('/candy'); }}><Text color={position === 'candy' ? 'tomato' : 'default'} fontSize={'2xl'} fontWeight={7000}>candy</Text></Link>
                    <Link onClick={() => { navigate('/date'); }}><Text color={position === 'date' ? 'tomato' : 'default'} fontSize={'2xl'} fontWeight={7000}>date</Text></Link>
                    <Link onClick={() => { navigate('/info'); }}><Text color={position === 'info' ? 'tomato' : 'default'} fontSize={'2xl'} fontWeight={7000}>.info</Text></Link>
                </Flex>
                <Flex alignItems={'center'} gap={4}>
                    <Button colorScheme='blue' variant='outline' onClick={() => { localStorage.removeItem('token'); navigate('/signin'); }}>
                        Sign out
                    </Button>
                    <ColorModeSwitcher />
                </Flex>
            </Flex>
        </Box>
    )
}
