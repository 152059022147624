import axios from "axios";

export const AxiosConfig = () => {
    // localStorage.removeItem('token');

    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    
    axios.interceptors.request.use(
      function (config) {
        if(localStorage.getItem('token')) {
            config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        }
        return config;
      }, function (error) {
        return Promise.reject(error);
      }
    );
    
    axios.interceptors.response.use(
      function (response) {
        if(response.headers['token']) {
            localStorage.setItem('token', response.headers['token']);
        }
        return response;
      }, function (error) {
        if(error.response && (error.response.status === 401 || error.response.status === 403)) {
          localStorage.removeItem('token');
          window.location = '/signin';
        }
        return Promise.reject(error);
      }
    );
}
