import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'

import AppContext, { updateUserContext } from '../appContext';

export const Home = () => {
    const navigate = useNavigate();

    const { setContext } = useContext(AppContext);
    
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const tokenParts = token.split('.');
            if (tokenParts.length === 3) {
                const payloadBase64Url = tokenParts[1];
                const payloadBase64 = payloadBase64Url.replace(/-/g, '+').replace(/_/g, '/');
                const payloadJson = decodeURIComponent(window.atob(payloadBase64).split('').map((c) => {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
                const payload = JSON.parse(payloadJson);
                if (payload) {
                    setContext(updateUserContext(payload))
                }
            } else {
                console.error("Invalid Token");
            }
            navigate('/candy');
        } else {
            navigate('/date');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
