import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, FormControl, FormLabel, Heading, Input, Stack } from '@chakra-ui/react'

import { Logo } from '../components/Logo'
import { PasswordField } from '../components/PasswordField'

import axios from 'axios';

export const Signup = () => {
  const navigate = useNavigate();

  const [userid, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    // event.preventDefault();

    try{
      const rsp = await axios({
        method: 'post',
        url: '/sign/up',
        data: { userid, password, username } 
      });

      alert(rsp.data.message);
      navigate('/signin')
    
    } catch(error){
      console.log(error.message);
    }   
  };
  
  return (
    <Container maxW='lg' py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
      <Stack spacing='8'>
      <Stack spacing='6'>
          <Logo />
          <Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
            <Heading size={{ base: 'xs', md: 'sm' }}>Sign up to your account</Heading>
          </Stack>
        </Stack>
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={{ base: 'transparent', sm: 'bg.surface' }}
          boxShadow={{ base: 'none', sm: 'md' }}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Stack spacing='6'>
            {/* <Heading size={{ base: 'xs', md: 'sm' }}>Sign Up</Heading> */}
            <Stack spacing='5'>
              <FormControl id='email' isRequired>
                <FormLabel>Email Address</FormLabel>
                <Input type='email' onChange={(e) => setUserId(e.target.value)} />
              </FormControl>
              <FormControl id='username'>
                <FormLabel>User Name</FormLabel>
                <Input type='text' onChange={(e) => setUsername(e.target.value)} />
              </FormControl>
              <PasswordField isRequired onChange={(e) => setPassword(e.target.value)} onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit(e); }}/>
            </Stack>
            <Button onClick={handleSubmit}>Sign Up</Button>  {/* Submit button */}
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}