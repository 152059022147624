import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Home } from './Home';
import { Candy } from './Candy';
import { Date } from './Date';
import { Info } from './Info';
import { Signin } from './Signin';
import { Signup } from './Signup';
import { NotFound404 } from './NotFound404';

export const PageRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/candy' element={<Candy />} />
        <Route path='/date' element={<Date />} />
        <Route path='/info' element={<Info />} />
        <Route path='/signin' element={<Signin />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/*' element={<NotFound404 />} />
      </Routes>
    </BrowserRouter>
  );
};
