import { createContext } from 'react';

export const updateUserContext = (values = {}) => ({ user: values });
export const User = { admin: '', candy: [] };

export const getDefaultState = () => {
  return {
    user: { admin: '', candy: [] }
  }
}

const AppContext = createContext({});
export default AppContext;