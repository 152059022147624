import { useRef, useState, useEffect, useContext } from  'react';
import { useLocation } from 'react-router-dom';

import { Header } from './Header';
import { Card, CardBody, CardHeader, CardFooter, SimpleGrid, Button, Box, Input, Textarea, Select, useToast, useDisclosure,
        Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
        FormControl, FormLabel, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark, Tooltip, } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons'

import axios from 'axios';
import AppContext from '../appContext';

export const Info = () => {
    const location = useLocation();
    const toast = useToast();
    const { user } = useContext(AppContext)

    const searchRef = useRef();
    const selectRef = useRef();

    const nullKeys = {"keys":[],"list_complete":true,"cacheStatus":null};
    const [keys, setKeys] = useState(nullKeys);
    const [values, setValues] = useState([]);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showTooltip, setShowTooltip] = useState(false)
    const [formKey, setFormKey] = useState('');
    const [formSummary, setFormSummary] = useState('');
    const [formScoreType, setFormScoreType] = useState('JI');
    const [formScoreValue, setFormScoreValue] = useState(100);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const key = urlParams.get('key');
        if (key) {
            searchRef.current.value = key
        }
        getKeys();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { getValues(); }, [keys]);

    const getKeys = async() => {
        try{
            const rsp = await axios({
                method: 'get',
                url: '/api/info',
                params: { search: searchRef.current.value } 
            });
            if(rsp.status === 200) {
                const data = rsp.data;
                const keyList = [];
                const index = selectRef.current.value;

                for (let key of data.keys) {
                    if (('AL' === index) || (index === key.metadata.index)) {
                        keyList.push(key)
                    }
                }
                data.keys = keyList;

                setKeys(data);
            } else {
                setKeys(nullKeys);
                toast({
                    title: `No data getKeys`,
                    status: 'info',
                    isClosable: true,
                });
            }
        } catch(error){
            setKeys(nullKeys);
            toast({
                title: `Error getKeys`,
                description: error.message,
                status: 'error',
                isClosable: true,
            });
        }
    }

    const getValues = async() => {
        const valueList = [];
        for(let key of keys.keys) {
            const value = await getValue(key);
            if (value) {
                valueList.push(value);
            }
        }
        setValues(valueList);
    }

    const getValue = async(key) => {
        if (!key || !key.name) {
            toast({
                title: `Error parameter missing`,
                status: 'error',
                isClosable: true,
            });
            return;
        }

        key = key.name.split(':')[1];
        try{
            const rsp = await axios({
                method: 'get',
                url: `/api/info/${key}` 
            });
            if(rsp.status === 200) {
                return rsp.data;
            } else {
                toast({
                    title: `Code: ${rsp.status}`,
                    status: 'waring',
                    isClosable: true,
                });
                return;
            }
        } catch(error){
            toast({
                title: `Error getValue`,
                description: error.message,
                status: 'error',
                isClosable: true,
            });
            return;
        }
    }

    const deleteInfo = async(index) => {
        if (index === undefined  || index < -1 || index >= values.length) {
            toast({
                title: `Error parameter missing`,
                status: 'error',
                isClosable: true,
            });
            return;
        }

        const value = values[index];
        const key = value.key;
        try{
            const rsp = await axios({
                method: 'delete',
                url: `/api/info/${key}` 
            });
            if(rsp.status === 200) {
                getKeys();
                return;
            } else {
                toast({
                    title: `Code: ${rsp.status}`,
                    status: 'waring',
                    isClosable: true,
                });
                return;
            }
        } catch(error){
            toast({
                title: `Error deleteInfo`,
                description: error.message,
                status: 'error',
                isClosable: true,
            });
            return;
        }
    }

    const addInfo = async() => {
        if (!formKey || !formSummary) {
            toast({
                title: `Error parameter missing`,
                status: 'error',
                isClosable: true,
            });
            return;
        }

        try{
            const rsp = await axios({
                method: 'post',
                url: '/api/info',
                data: { key: formKey, summary: formSummary, index: formScoreType, score: formScoreValue }
            });
            if(rsp.status === 200) {
                toast({
                    title: `Add data`,
                    status: 'success',
                    isClosable: true,
                });
                getKeys();
            } else {
                toast({
                    title: `Code: ${rsp.status}`,
                    status: 'waring',
                    isClosable: true,
                });
            }
        } catch(error){
            toast({
                title: `Error add data`,
                description: error,
                status: 'error',
                isClosable: true,
            });
        } finally {
            setFormKey('');
            setFormSummary('');
            setFormScoreType('JI');
            setFormScoreValue(100);
            onClose();
        }
    }

    return (
        <>
            <Header position={'info'} />
            <Box margin='5px' display='flex' alignItems='center' height='50px'>
                <Select ref={selectRef} onChange={getKeys} margin='5px' maxW='250px'>
                    <option value='AL'>ALL INFO</option>
                    <option value='JI'>Japanese imperialism</option>
                    <option value='ST'>Stalinism</option>
                    <option value='DP'>Despotism</option>
                </Select>
                <Button onClick={getKeys} margin='5px'>SEARCH</Button>
                <Input ref={searchRef} onKeyUp={(e) => { if (e.key === 'Enter') getKeys(e); }} margin='5px' type='text' placeholder='SEARCH...'/>
                {(user.admin === 'info' || user.admin === 'root')?<Button onClick={onOpen} margin='5px'>ADD</Button>:null}
            </Box>
            <SimpleGrid flexWrap="wrap" width='100%' padding='5px' margin='5px' minChildWidth='sm' spacing='10px'>
                {values.map((value, idx) => (
                    <Box key={idx} maxW='lg' mr={['0', '5px']} flexGrow={[1, 0]}>
                        <Card>
                            <CardHeader fontWeight={700} display="flex" justifyContent="space-between" alignItems="center">
                                <div>{value.key}</div>
                                {(user.admin === 'info' || user.admin === 'root')?
                                    <div style={{display: 'flex', gap: '10px', color: 'gray'}}>
                                        <DeleteIcon onClick={() => deleteInfo(idx)} cursor="pointer"/>
                                    </div>
                                :null}
                            </CardHeader>
                            <CardBody>
                                <div dangerouslySetInnerHTML={{__html: value.summary}} />
                            </CardBody>
                            <CardFooter justifyContent='flex-end'>
                                <div>{value.index}: {value.score}</div>
                            </CardFooter>
                        </Card>
                    </Box>
                ))}
            </SimpleGrid>
            <Modal closeOnOverlayClick={false} scrollBehavior={'inside'} size={'sm'} 
                isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>ADD INFO</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>제목</FormLabel>
                            <Input onChange={(e) => setFormKey(e.target.value)} value={formKey}/>
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>내용</FormLabel>
                            <Textarea onChange={(e) => setFormSummary(e.target.value)} value={formSummary}/>
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>점수</FormLabel>
                            <Select onSelect={(e) => setFormScoreType(e.target.value)} defaultValue={formScoreType}>
                                <option value='JI'>Japanese imperialism</option>
                                <option value='ST'>Stalinism</option>
                                <option value='DP'>Despotism</option>
                            </Select>
                            <Slider min={1} max={100} colorScheme='teal'
                                onChange={(v) => setFormScoreValue(v)} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                                <SliderMark value={25} mt='1' ml='-2.5' fontSize='sm'>25</SliderMark>
                                <SliderMark value={50} mt='1' ml='-2.5' fontSize='sm'>50</SliderMark>
                                <SliderMark value={75} mt='1' ml='-2.5' fontSize='sm'>75</SliderMark>
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <Tooltip hasArrow bg='teal.500' color='white' placement='top' isOpen={showTooltip} label={`${formScoreValue}`}>
                                    <SliderThumb />
                                </Tooltip>
                            </Slider>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={addInfo} colorScheme='blue' mr={3}>Save</Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
