import { useRef, useState, useEffect, useContext } from  'react';
import { useLocation } from 'react-router-dom';

import { Header } from './Header';
import { Card, CardBody, CardHeader, CardFooter, SimpleGrid, Button, Box, Input, useToast } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

import axios from 'axios';
import AppContext from '../appContext';

export const Candy = () => {
    const location = useLocation();
    const toast = useToast();
    const { user } = useContext(AppContext)

    const inputRef = useRef();

    const nullKeys = {"keys":[],"list_complete":true,"cacheStatus":null}
    const [keys, setKeys] = useState(nullKeys);
    const [values, setValues] = useState([]);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const key = urlParams.get('key');
        if (key) {
            inputRef.current.value = key
        }
        getKeys();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { getValues(); }, [keys]);

    const getKeys = async() => {
        try{
            const rsp = await axios({
                method: 'get',
                url: '/api/candy',
                params: { search: inputRef.current.value } 
            });
            if(rsp.status === 200) {
                setKeys(rsp.data);
            } else {
                setKeys(nullKeys);
                toast({
                    title: `No data getKeys`,
                    status: 'info',
                    isClosable: true,
                });
            }
        } catch(error){
            setKeys(nullKeys);
            toast({
                title: `Error getKeys`,
                description: error.message,
                status: 'error',
                isClosable: true,
            });
        }
    }

    const getValues = async() => {
        const valueList = [];
        for(let key of keys.keys) {
            const value = await getValue(key);
            if (value) {
                valueList.push(value);
            }
        }
        setValues(valueList);
    }

    const getValue = async(key) => {
        if (!key || !key.name) {
            toast({
                title: `Error parameter missing`,
                status: 'error',
                isClosable: true,
            });
            return;
        }

        key = key.name.split(':')[1];
        try{
            const rsp = await axios({
                method: 'get',
                url: `/api/candy/${key}` 
            });
            if(rsp.status === 200) {
                return rsp.data;
            } else {
                toast({
                    title: `Code: ${rsp.status}`,
                    status: 'waring',
                    isClosable: true,
                });
                return;
            }
        } catch(error){
            toast({
                title: `Error getValue`,
                description: error.message,
                status: 'error',
                isClosable: true,
            });
            return;
        }
    }

    const toggleCandy = async (index) => {
    }

    const getMyCandy = async () => {
        if (user && user.mycandy) {
            const myCandyKeys = Object.assign({}, nullKeys);
            myCandyKeys.keys = user.mycandy;
            setKeys(myCandyKeys);
        }
    }

    return (
        <>
            <Header position={'candy'} />
            <Box margin='5px' display='flex' alignItems='center' height='50px'>
                <Button onClick={getMyCandy} margin='5px'>MY CANDY</Button>
                <Button onClick={getKeys} margin='5px'>SEARCH</Button>
                <Input ref={inputRef} onKeyUp={(e) => { if (e.key === 'Enter') getKeys(e); }} margin='5px' type='text' placeholder='SEARCH...'/>
            </Box>
            <SimpleGrid flexWrap="wrap" width='100%' padding='5px' margin='5px' minChildWidth='sm' spacing='10px'>
                {values.map((value, idx) => (
                    <Box key={idx} maxW='lg' mr={['0', '5px']} flexGrow={[1, 0]}>
                        <Card>
                            <CardHeader fontWeight={700} display="flex" justifyContent="space-between" alignItems="center">
                                <a href={value.url} target='_blank' rel='noreferrer'>{value.key}</a>
                                <div style={{display: 'flex', gap: '10px', color: 'gray'}}>
                                    <CheckIcon onClick={() => toggleCandy(idx)} cursor="pointer"/>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div>출생: {value.bone}</div>
                                <div>경력: {value.career.map((s,i) => (<span key={i}>{s}<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>))}</div>
                            </CardBody>
                            <CardFooter justifyContent='flex-end'>
                                {value.index.map((idx) => {
                                    const key = Object.keys(idx)[0]; 
                                    return <div>{`${key}: ${idx[key]}`}</div>
                                })}
                            </CardFooter>
                        </Card>
                    </Box>
                ))}
            </SimpleGrid>
        </>
    )
}
